<template>
  <employee-resources/>
</template>

<script>
import EmployeeResources from "../components/EmployeeResources.vue";

export default {
  components: { EmployeeResources },
};
</script>